import React, { Component } from 'react'
import FieldCheckbox from './field/checkbox'

class SelectGroup extends Component {
  constructor() {
    super()
    this.state = {
      toggled: false,
    }
  }

  toggle = () => {
    this.setState({toggled: !this.state.toggled});
    this.props.all()
  }

  toggleAll = () => {
    const toggled = !this.state.toggled;
    this.setState({toggled});
    this.props.all(toggled
      ? this.props.options.map(option => option.id)
      : []
    )
  }

  isChecked = id => {
    const {values} = this.props
    return values.indexOf(id) !== -1
  }

  render() {
    const { id, options, onChange } = this.props

    return (
      <>
        <FieldCheckbox id={`${id}-all`} label="Select All" modifier="selectgroup--all" checked={this.state.toggled} onChange={this.toggleAll} />
        {options.map((option, i) => (
          <FieldCheckbox
            key={i}
            id={option.id}
            label={option.label}
            checked={this.isChecked(option.id)}
            onChange={onChange}
          />
        ))}
      </>

    )
  }
}

export default SelectGroup
