import React from 'react'

const SvgIcon = ({ children }) => (
  <svg
    className="field-checkbox--icon"
    width="17"
    height="13"
    viewBox="0 0 17 13"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {children}
  </svg>
)

const FieldCheckbox = ({ id, label = '', modifier = '', disabled, checked, onChange, tabIndex }) => (
  <div className={`field-checkbox ${modifier}`}>
    <label className="field-checkbox--label" htmlFor={id}>
      <input type="checkbox" id={id} name={id} value={id} disabled={disabled} checked={checked} onChange={onChange} tabIndex={tabIndex}/>
      <span className="field-checkbox--input">
        <SvgIcon>
          <g transform="translate(-3.000000, -1.000000)" fill="#51AF35" fillRule="nonzero">
            <path d="M6.41421356,6.58578644 C5.63316498,5.80473785 4.36683502,5.80473785 3.58578644,6.58578644 C2.80473785,7.36683502 2.80473785,8.63316498 3.58578644,9.41421356 L7.58578644,13.4142136 C8.36683502,14.1952621 9.63316498,14.1952621 10.4142136,13.4142136 L19.4142136,4.41421356 C20.1952621,3.63316498 20.1952621,2.36683502 19.4142136,1.58578644 C18.633165,0.804737854 17.366835,0.804737854 16.5857864,1.58578644 L9,9.17157288 L6.41421356,6.58578644 Z" />
          </g>
        </SvgIcon>
      </span>
      <span className="field-checkbox--text">{label}</span>
    </label>
  </div>
);

export default FieldCheckbox

