import React from 'react'


const SvgIcon = ({ id, color, children }) => (
  <svg
    className="daterange--icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>{children}</defs>
    <g fill="none" fillRule="evenodd">
      <mask id={`mask-${id}`} fill={color}>
        <use xlinkHref={`path-${id}`} />
      </mask>
      <use fill={color} fillRule="nonzero" xlinkHref={`#path-${id}`} />
      <g mask={`url(#mask-${id})`} fill={color}>
        <rect width="24" height="24" />
      </g>
    </g>
  </svg>
)

const DateField = ({ label, id, children }) => (
  <div className="field-text">
    <label htmlFor={id}>
      <span className="field-text--label">{label}</span>
    </label>
    <span className="field-text--input">
      {children}
      {/*
        * wrapping the DayPickerInput in 1 label was interfering with focus
        * fairly harmless hack to add 2nd label around svg.
        * could try making it a background-image
        * but the entire field-text--input should be "clickable"
        * */}
      <label className="field-text--label-icon" htmlFor={id}>
        <SvgIcon color="#BBC5D9" id={`calendar-${id}`}>
          <path
            d='M4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 Z M5,19 L19,19 L19,5 L5,5 L5,19 Z M5,7 L19,7 L19,9 L5,9 L5,7 Z M5,11 L19,11 L19,13 L5,13 L5,11 Z M5,15 L19,15 L19,17 L5,17 L5,15 Z M7,9 L9,9 L9,19 L7,19 L7,9 Z M11,9 L13,9 L13,19 L11,19 L11,9 Z M15,9 L17,9 L17,19 L15,19 L15,9 Z'
            id={`path-calendar-${id}`}
          />
        </SvgIcon>
      </label>
    </span>
  </div>
)

export default DateField
