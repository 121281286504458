import React from 'react'

const PageTitle = ({ title, children, modifiers = [] }) => {
  const classes = `page--header-title ${modifiers.map(mod => `page--header-title--${mod}`).join(' ')}`;

  return (
    <div className="page--header">
      <h1 className={classes}>{title}</h1>
      {children && <div className="page--header-action">
        {children}
      </div>}
    </div>
  );
};

export default PageTitle
