import React, {Component} from 'react'

const SvgIcon = ({ open, children }) => (
  <svg
    className={`filter--icon ${open ? 'filter--icon__is-open' : 'filter--icon__is-closed'}`}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {children}
  </svg>
)

class FilterPane extends Component {

  constructor() {
    super()
    this.state = {
      init: false,
      open: false
    }
  }

  toggle = () => {
    if (!this.state.init) {
      // Only add the closed class after first toggle to prevent page load animation.
      this.setState({init: true});
    }
    this.setState({open: !this.state.open});
  }

  render() {
    const {title, children} = this.props;

    return (
      <div className={`filter-pane filter-pane--divider ${this.state.init ? (this.state.open ? 'is-open' : 'is-closed') : ''}`}>
        <div className="filter-pane--header filter-pane--toggle" onClick={this.toggle}>
          <h4 className="filter-pane--title">{title}</h4>
          <SvgIcon open={this.state.open}>
            <path fill="#8C95AC" d="M16.2969948,8.29289322 C16.6875191,7.90236893 17.3206841,7.90236893 17.7112083,8.29289322 C18.1017326,8.68341751 18.1017326,9.31658249 17.7112083,9.70710678 L12.7112083,14.7071068 C12.3207973,15.0975179 11.6878559,15.0976476 11.2972847,14.7073966 L6.29318318,9.70739663 C5.90249881,9.31703248 5.90223922,8.68386755 6.29260337,8.29318318 C6.68296752,7.90249881 7.31613245,7.90223922 7.70681682,8.29260337 L12.0038117,12.5860763 L16.2969948,8.29289322 Z"></path>
          </SvgIcon>
        </div>
        <div className="filter-pane--content">
          <div className="filter-pane--content-group">
            {children}
          </div>
        </div>
      </div>
    )
  }

}

export default FilterPane
