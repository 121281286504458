import React, { Component } from 'react'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'

import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'

import DateField from './dateField'
import CalendarCaption from './../calendar/caption'

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, { locale })
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
  return undefined
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale })
}

const FORMAT = 'MM/DD/YYYY';

class DateRange extends Component {

  constructor(props) {
    super(props);
    this.toInput = React.createRef()
  }

  handleFromChange = day => {
    this.props.onChange({from: day})
  }

  handleToChange = day => {
    this.props.onChange({to: day})
  }

  render() {
    const {from, to} = this.props;
    const modifiers = {
      start: from,
      end: to
    }

    return (
      <div className="date-picker date-picker--range">
        <DateField label="From" id="date-from">
          <DayPickerInput
            value={from}
            onDayChange={this.handleFromChange}
            inputProps={{id: 'date-from'}}
            formatDate={formatDate}
            format={FORMAT}
            parseDate={parseDate}
            placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { after: to },
              toMonth: to,
              firstDayOfWeek: 1,
              modifiers,
              onDayClick: () => this.toInput.current.getInput().focus(),
              captionElement: ({date}) => (<CalendarCaption date={date} />)
            }}
          />
        </DateField>
        <DateField label="To" id="date-to">
          <DayPickerInput
            ref={this.toInput}
            value={to}
            onDayChange={this.handleToChange}
            inputProps={{id: 'date-to'}}
            formatDate={formatDate}
            format={FORMAT}
            parseDate={parseDate}
            placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              fromMonth: from,
              month: from,
              modifiers,
              captionElement: ({date}) => (<CalendarCaption date={date} />)
            }}
          />
        </DateField>
      </div>
    )
  }
}

export default DateRange
