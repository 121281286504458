/**
 * A simplification of React Table 6.8.6 to remove the dependency.
 * There were React version dependencies that were preventing us from upgrading
 * and ReactTable had more features than we needed.
 **/
import React from 'react'
import classnames from 'classnames'

function asPx (value) {
  value = Number(value)
  return Number.isNaN(value) ? null : `${value}px`
}

const TrGroupComponent = ({ children, className, ...rest }) => (
  <div className={classnames('rt-tr-group', className)} role="rowgroup" {...rest}>
    {children}
  </div>
);

const TrComponent = ({ children, className, ...rest }) => (
  <div className={classnames('rt-tr', className)} role="row" {...rest}>
    {children}
  </div>
);

const ThComponent = ({
  toggleSort, className, children, ...rest
}) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div
    className={classnames('rt-th', className)}
    onClick={e => toggleSort && toggleSort(e)}
    role="columnheader"
    tabIndex="-1" // Resolves eslint issues without implementing keyboard navigation incorrectly
    {...rest}
  >
    {children}
  </div>
);

const TdComponent = ({
  toggleSort, className, children, ...rest
}) => (
  <div className={classnames('rt-td', className)} role="gridcell" {...rest}>
    {children}
  </div>
);

const getAccessor = (column, row) => {
  if (typeof column.accessor === 'function' && row) {
    return column.accessor(row);
  }
  return column.accessor;
}

const ComplexTable = (props) => {
  const {
    columns = [],
    data = [],
    className = '',
    getTrGroupProps = () => {}
  } = props;
  return (
    <div className={classnames("ReactTable", className)}>
      <div className="rt-table" role="grid">
        <div className="rt-thead -header" >
          <TrComponent key="rt-thead">
            {columns.map((column, columnIndex) => {
              const minWidth = column.minWidth || 100;
              const width = column.width || minWidth;
              const flex = column.width || minWidth;
              const maxWidth = column.maxWidth;
              const key = typeof column.accessor === 'string' ? column.accessor : column.id ? column.id : columnIndex;
              const thProps = {
                key: column.id || column.accessor,
                style: {
                  ...column.styles,
                  flex: `${flex} 0 auto`,
                  width: asPx(width),
                  maxWidth: asPx(maxWidth),
                }
              }
              return (
                <ThComponent className={column.headerClassName} {...thProps} key={key}>
                  <div className="">{column.Header}</div>
                </ThComponent>
            )})}
          </TrComponent>
        </div>
        <div className="rt-tbody" >
          {data.map((row, rowIndex) => {
            const rowInfo = {
              index: rowIndex,
              viewIndex: (rowIndex + 1),
              row
            }
            const trGroupProps = getTrGroupProps(props, rowInfo);
            return (
            <TrGroupComponent key={rowIndex} {...trGroupProps}>
              <TrComponent>
                {columns.map(column => {
                  const minWidth = column.minWidth || 100;
                  const width = column.width || minWidth;
                  const flex = column.width || minWidth;
                  const maxWidth = column.maxWidth;
                  const accessor = getAccessor(column, row);
                  const key = typeof column.accessor === 'string' ? column.accessor : column.id ? column.id : columnIndex;
                  const tdProps = {
                    index: rowIndex,
                    style: {
                      ...column.styles,
                      flex: `${flex} 0 auto`,
                      width: asPx(width),
                      maxWidth: asPx(maxWidth),
                    }
                  }

                  const cellProps = {
                    index: rowIndex,
                    value: typeof column.accessor == 'function' ? column.accessor(row) : row[accessor],
                    row
                  }
                  return (
                    <TdComponent key={key} {...tdProps}>
                      {column.Cell ? column.Cell(cellProps) : cellProps.value}
                    </TdComponent>
                  );
                })}
              </TrComponent>
            </TrGroupComponent>
            )
          }
          )}
        </div>
        <div className="rt-foot -footer" >
          <TrComponent key="rt-foot">
            {columns.map((column, columnIndex) => {
              const minWidth = column.minWidth || 100;
              const width = column.width || minWidth;
              const flex = column.width || minWidth;
              const maxWidth = column.maxWidth;
              const key = typeof column.accessor === 'string' ? column.accessor : column.id ? column.id : columnIndex;
              let tdProps = {
                key: column.id || column.accessor,
                style: {
                  ...column.styles,
                  flex: `${flex} 0 auto`,
                  width: asPx(width),
                  maxWidth: asPx(maxWidth),
                }
              }
              if (typeof column.getFooterProps === 'function') {
                tdProps = {...tdProps, ...column.getFooterProps()}
              }

              return (
                <TdComponent className={column.footerClassName} {...tdProps} key={key}>
                  <div className="">{typeof column.Footer === 'function' ? column.Footer() : null}</div>
                </TdComponent>
            )})}
          </TrComponent>
        </div>
      </div>
    </div>
    );
  }

  export default ComplexTable
