export const data = [{
  name: 'Lena Meyer',
  folder: '230882-18',
  date: '2/28/18',
  payment: {
    type: 'Deposit',
    amount: 100,
    balance: 550,
    method: 'Credit Card',
  }
},
{
  name: 'Gabriel Steele',
  folder: '230883-18',
  date: '3/01/18',
  payment: {
    type: 'Full Amount',
    amount: 650,
    balance: 0,
    method: 'Check',
  }
},
{
  name: 'Jesse Goodman',
  folder: '230884-18',
  date: '3/02/18',
  payment: {
    type: 'Deposit',
    amount: 100,
    balance: 550,
    method: 'Cash',
  }
},
{
  name: 'Milton Roy',
  folder: '230885-18',
  date: '3/03/18',
  payment: {
    type: 'Refund',
    amount: -100,
    balance: 0,
    method: 'Credit Card',
  }
}]