import React, { useContext } from 'react'
import moment from 'moment-timezone';
import LocationContext from './../../context/location';

const label = date => {
  const location = useContext(LocationContext);

  const dateObjConverted = moment.utc(date).tz(location.timezone());
  const parts = {
    day: dateObjConverted.format('DD'),
    month: dateObjConverted.format('MM'),
    year: dateObjConverted.format('YY')
  }

  return (<span>{parts.month}<span className="date--sep">/</span>{parts.day}<span className="date--sep">/</span>{parts.year}</span>);
}
const DateLabel = ({ date }) => {
  if (!date) {
    return '';
  }
  return <div className="date date--dmy" data-date={date}>{label(date)}</div>;
}

export default DateLabel
