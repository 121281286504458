import React from 'react'

const FilterPanel = ({ title, children }) => (
  <div className="filter-panel">
    <h3 className="filter-panel--title">{title}</h3>
    {children}
  </div>
)

export default FilterPanel
