import React, { Component } from 'react'

import Button from '../components/button'
import Card from '../components/card'
import ClientName from '../components/client/name'
import ComplexTable from '../components/ComplexTable'
import Currency from '../components/currency'
import DashboardLayout from '../components/dashboard/DashboardLayout';
import DateLabel from '../components/date/label'
import DateRange from '../components/date/range'
import FilterPane from '../components/filter/pane'
import FilterPanel from '../components/filter/panel'
import Icon from '../components/icon'
import LocationsFilter from '../components/filter/locations'
import Page from '../components/page';
import PageTitle from '../components/pageTitle'

// mock data
import { data } from '../store/mock-accounting'

const columns = [{
  Header: 'Client',
  accessor: 'name',
  cell: props => <ClientName name={props.value} folder={props.original.folder} />
}, {
  Header: 'Date',
  accessor: 'date',
  cell: props => <DateLabel date={props.value} />
}, {
  id: 'paymentType',
  Header: 'Type',
  accessor: d => <span className='row-value'>{d.payment.type}</span>
}, {
  id: 'paymentMethod',
  Header: 'Method',
  accessor: d => <span className='row-value'>{d.payment.method}</span>
}, {
  id: 'paymentAmount',
  Header: 'Amount',
  accessor: d => <Currency amount={d.payment.amount} />
}, {
  id: 'paymentBalance',
  Header: 'Balance',
  accessor: d => <Currency amount={d.payment.balance} />
}]

class Accounting extends Component {
  constructor() {
    super()
    this.state = {
      filter: {
        from: undefined,
        to: undefined,
        locations: []
      }
    }
  }

  setDates = date => {
    const {filter} = this.state

    this.setState({filter: Object.assign(filter, date)})
  }

  toggleChecked = event => {
    let locations = [...this.state.filter.locations]
    const index = locations.indexOf(event.target.value)

    if (index === -1) {
      locations.push(event.target.value)
    }
    else {
      locations.splice(index, 1)
    }

    const filter = Object.assign({}, this.state.filter, {locations})
    this.setState({filter});
  }

  toggleAll = (values) => {
    const locations = values;
    const filter = Object.assign({}, this.state.filter, { locations })
    this.setState({ filter });
  }

  render() {
    const {locations, from, to} = this.state.filter;

    return (
    <Page>
      <DashboardLayout>
        <div className="container">
          <div className="container--main">
            <PageTitle title="Accounting">
              <Button theme="primary">
                <Icon theme="primary" icon="download" />
                Export Results
              </Button>
            </PageTitle>
          </div>
        </div>
        <div className="container">
          <div className="container--main">
            <Card>
              <ComplexTable
                data={data}
                columns={columns}
                showPagination={false}
                defaultSorted={[{id: 'date', desc: false}]}
                resizable={false}
              />
            </Card>
          </div>
          <aside className="container--aside">
            <FilterPanel title="Filter Results" >
              <FilterPane title="Date">
                <DateRange from={from} to={to} onChange={this.setDates} />
              </FilterPane>
              <LocationsFilter values={locations} onChange={this.toggleChecked} all={this.toggleAll} />
            </FilterPanel>
          </aside>
        </div>
      </DashboardLayout>
    </Page>
    )
  }
}

export default Accounting
