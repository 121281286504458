import React, { useContext, useEffect } from 'react'

import FilterPane from './pane'
import SelectGroup from '../selectGroup'
import LocationsAPIContext from '../../services/locations';

const LocationsFilter = ({
  title = 'Locations',
  locationList = [],
  onChange,
  values,
  all
}) => {
  const api = useContext(LocationsAPIContext);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    api.load();
  }, []);

  return (
    <FilterPane title={title}>
      <SelectGroup options={api.options} values={values} onChange={onChange} all={all} />
    </FilterPane>
  );
}

export default LocationsFilter
